import React from "react";
import { Link } from "gatsby";
import blog1 from "../assets/images/blog-1-1.jpg";
import blog2 from "../assets/images/blog-1-2.jpg";
import blog3 from "../assets/images/blog-1-3.jpg";
import blog4 from "../assets/images/blog-1-4.jpg";
import blog5 from "../assets/images/blog-1-5.jpg";
import blog6 from "../assets/images/blog-1-6.jpg";
const Games = () => {
  return (
    <section className="blog-one blog-page">
      <div className="container">
        <div class="row">
          <div class="col-md-6 text-center">
            <iframe
              src="https://www.wordwall.net/embed/600fbe47bcc34f40816dbdb69755ee25?themeId=1&amp;templateId=2"
              allowfullscreen=""
              width="500"
              height="380"
              frameborder="0"
            ></iframe>
            <h2 className="blog-one__title text-center">Group sort</h2>
          </div>
          <div class="col-md-6 text-center">
            <iframe
              src="https://www.wordwall.net/embed/625619ca23244137892e8da0e07372e7?themeId=1&amp;templateId=3"
              allowfullscreen=""
              width="500"
              height="380"
              frameborder="0"
            ></iframe>
            <h2 className="blog-one__title text-center">Match up</h2>
          </div>
          <div class="col-md-6 text-center">
            <iframe
              src="https://www.wordwall.net/embed/0c28363a87134fe78b97b6613ce38579?themeId=1&amp;templateId=46"
              allowfullscreen=""
              width="500"
              height="380"
              frameborder="0"
            ></iframe>
            <h2 className="blog-one__title text-center">Find the match - 1</h2>
          </div>
          <div class="col-md-6 text-center">
            <iframe
              src="https://www.wordwall.net/embed/b447a2fd8468411aa6805e271b87a67e?themeId=1&amp;templateId=46"
              allowfullscreen=""
              width="500"
              height="380"
              frameborder="0"
            ></iframe>
            <h2 className="blog-one__title text-center">Find the match - 2</h2>
          </div>
          <div class="col-md-12 text-center">
            <iframe
              src="https://www.wordwall.net/embed/21d1588dcef6493490482dc457d531ac?themeId=49&amp;templateId=45"
              allowfullscreen=""
              width="500"
              height="380"
              frameborder="0"
            ></iframe>
            <h2 className="blog-one__title text-center">Whack-a-mole</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Games;
