import React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Games from "../components/Games";

const GamesPage = () => {
  return (
    <Layout pageTitle="Kipso | Edugames">
      <Navbar />
      <PageHeader title="Edugames" />
      <Games />
      <Footer />
    </Layout>
  );
};

export default GamesPage;
